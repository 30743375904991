















































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import AccordionHeader from './components/AccordionHeader/index.vue';

const DEFAULT_COLOR_ACCORDION = 'var(--primarycolor-lightest)';

@Component({
  components: {
    AccordionHeader,
  },
})
export default class Accordion extends Vue {
  @Prop() id!: string;
  @Prop() name!: string;
  @Prop() padding!: string;
  @Prop() isStopped!: boolean;
  @Prop() haveStop!: boolean;
  @Prop() border!: string;
  @Prop() show!: boolean;
  @Prop() background!: string;
  @Prop() parentID!: string;
  @Prop() noIcon!: boolean;
  @Prop() idLastItem!: any;
  @Prop() idItem!: any;
  @Prop({ default: false }) showArrow!: boolean;
  @Prop({ default: false }) showAdditionalHeaderWhenOpen!: boolean;

  private changeSymbol = !this.show;

  mounted() {
    this.setCollapsible();
  }

  get isLastItem() {
    return this.idItem === this.idLastItem;
  }

  get backgroundColorAccordion() {
    return !this.changeSymbol && this.background ? DEFAULT_COLOR_ACCORDION : 'none';
  }

  @Watch('changeSymbol')
  emitChangeSymbol() {
    this.$emit('change-symbol', this.changeSymbol, this.idItem);
  }

  setCollapsible() {
    setTimeout(() => {
      const myCollapsible = document.getElementById(`button-${this.id}`) as HTMLElement;

      if (!myCollapsible) return;

      myCollapsible.addEventListener('show.bs.collapse', () => {
        this.changeSymbol = false;
      });

      myCollapsible.addEventListener('hidden.bs.collapse', () => {
        if (!myCollapsible.classList.contains('show')) {
          this.changeSymbol = true;
        }
      });
    }, 500);
  }
}
