
















































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import Arrow from '@/assets/icons/Arrow.vue';

const DEFAULT_COLOR_HEADER = 'var(--primarycolor-lightest)';

@Component({
  components: {
    Arrow,
  },
})
export default class Accordion extends Vue {
  @Prop() id!: string;
  @Prop() name!: string;
  @Prop() padding!: string;
  @Prop() isStopped!: boolean;
  @Prop() haveStop!: boolean;
  @Prop() border!: string;
  @Prop() background!: string;
  @Prop() noIcon!: boolean;
  @Prop() changeSymbol!: boolean;
  @Prop() isLastItem!: boolean;
  @Prop({ default: false }) showArrow!: boolean;
  @Prop({ default: false }) showAdditionalWhenOpen!: boolean;

  get stoppedCustomStyle() {
    return {
      transform: 'translate(-85px, -40px)',
    };
  }

  get backgroundColorHeader() {
    return !this.changeSymbol ? this.background || DEFAULT_COLOR_HEADER : 'none';
  }

  get showAdditional() {
    if (this.showAdditionalWhenOpen) return true;

    if (!this.showAdditionalWhenOpen && this.changeSymbol) return true;

    return false;
  }
}
